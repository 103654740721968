@import "@/shared/ui/config/scss/_index";

.callback {
  background: url($srcSharedImagesPath + "decor/decoration-bg-1.svg") no-repeat
    calc(50% - 80px) top;
  margin-bottom: 160px;
  padding-bottom: 30px;
  position: relative;

  @media (max-width: 1150px) {
    background-position-y: bottom;
  }
  @media (max-width: 950px) {
    padding-top: 75px;
    background-position-y: top;
    padding-bottom: 124px;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
    margin-bottom: 90px;
  }

  &__items {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;

    @media (max-width: 1150px) {
      align-items: center;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__column {
  }

  &__column-left {
    width: 57%;
    width: 567px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;
    margin-left: auto;

    @media (max-width: 950px) {
      display: none;
    }

    @media (max-width: 768px) {
      margin-bottom: 40px;
      margin-right: 0;
    }

    @media (max-width: 475px) {
      width: 100%;
    }
  }

  &__image {
    margin-top: 30px;
    width: 100%;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__column-right {
    width: 416px;
    margin-left: auto;
    margin-right: 80px;

    @media (max-width: 1150px) {
      margin-right: 0;
    }

    @media (max-width: 950px) {
      width: 100%;
      max-width: 600px;
      margin-left: 0;
    }

    @media (max-width: 430px) {
      width: auto;
    }
  }

  &__form,
  .popup &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-title {
      font-size: 40px;
      margin-bottom: 12px;
      font-weight: 700;

      @media (max-width: 950px) {
        font-size: 50px;
      }

      @media (max-width: 560px) {
        font-size: 35px;
      }
    }

    &-subtitle {
      color: #484748;
      display: block;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 950px) {
        font-size: 25px;
        max-width: 408px;
        margin-left: auto;
        margin-right: auto;
      }
      @media (max-width: 560px) {
        font-size: 18px;
      }
    }

    &-inputs-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;
      @media (max-width: 950px) {
        justify-content: flex-start;
      }

      @media (max-width: 430px) {
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;
      }

      & .callback__form-control {
        width: calc(50% - (24px / 2));

        @media (max-width: 950px) {
          max-width: 195px;
        }

        @media (max-width: 560px) {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    &-control {
      padding: 9px 16px;
      border-radius: 10px;
      border: 1px solid #dcdcdc;
      background: #fff;
      color: #909090;
      font-family: "Circe";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%; /* 24.8px */

      &::placeholder {
        color: #909090;
        font-size: 16px;
      }

      &:hover::placeholder {
        color: #303030;
      }

      &:focus {
        border-color: #909090;
        outline: none;
      }
    }

    &-control-email {
      width: 100%;
    }

    &-control-textarea {
      width: 100%;
      resize: none;
      height: 148px;
    }

    &-label {
      position: relative;
      display: block;
      padding-left: 44px;

      a {
        text-decoration: underline;
        color: $color-text-dark;
      }
    }

    input[type="checkbox"] {
      display: none;

      & + label {
        position: relative;
        font-size: 14px;
        color: #3e3e3f;
        cursor: pointer;

        @media (max-width: 950px) {
          font-size: 15px;
        }

        @media (max-width: 768px) {
          font-size: 14px;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid #909090;
          background: transparent
            url($templateRootPath + "assets/icons/check-circle.svg") center;
          position: absolute;
          top: 50%;
          left: 6px;
          transform: translateY(-50%);
          transition: all 0.3s;
        }
        @media (min-width: 768px) {
          &:hover:before {
            border-color: #474747;
            background: transparent
              url($templateRootPath + "assets/icons/tick-grey.svg") center;
          }
        }
      }

      &:checked {
        + label {
          &:before {
            border-color: #474747;
            background: transparent
              url($templateRootPath + "assets/icons/tick-black.svg") center;
          }
          @media (min-width: 768px) {
            &:hover:before {
              border-color: #474747;
              background: transparent
                url($templateRootPath + "assets/icons/tick-white.svg") center;
            }
          }
        }
      }
    }

    &-link {
      text-decoration: underline;
      color: #3e3e3f;
    }

    &-button {
      width: max-content;
      @media (max-width: 950px) {
        width: 100%;
        max-width: 375px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
